import React from 'react';
import Phone from '@mui/icons-material/LocalPhone';
import Email from '@mui/icons-material/Email';
import LinkedIn from '@mui/icons-material/LinkedIn';

function ContactMePage() {
    return (
        <div className="min-h-screen bg-gray-800 text-white flex flex-col items-center">
            <h1 className="text-4xl font-bold text-gray-300 my-8">Contact Me</h1>
            <div className="w-full max-w-4xl flex flex-wrap md:flex-nowrap justify-between p-4">
                {/* Contact Information Cards */}
                <div className="w-full md:w-1/2 flex flex-col space-y-4 p-4">
                    <div className="bg-gray-700 p-6 rounded-lg shadow-md">
                        <Phone />
                        <h2 className="text-2xl font-bold text-gray-300 mb-2">Phone</h2>
                        <p className="text-gray-500">+39 320 152 3513</p>
                    </div>
                    <div className="bg-gray-700 p-6 rounded-lg shadow-md">
                        <Email />
                        <h2 className="text-2xl font-bold text-gray-300 mb-2">Email</h2>
                        <p className="text-gray-500">alessio.taruffi2020@gmail.com</p>
                    </div>
                    <div className="bg-gray-700 p-6 rounded-lg shadow-md">
                        <LinkedIn />
                        <h2 className="text-2xl font-bold text-gray-300 mb-2">LinkedIn</h2>
                        <a href="https://www.linkedin.com/in/alessio-taruffi-8bb809288/" className="text-blue-400 hover:underline">@Alessio Taruffi</a>
                    </div>
                </div>
                
                {/* Contact Form */}
                <div className="w-full md:w-1/2 p-4">
                    <form action="https://formspree.io/f/meqywbgo" method="POST" className="bg-gray-700 p-6 rounded-lg shadow-md">
                        <div className="mb-4">
                            <label className="block text-gray-300 text-sm font-bold mb-2" htmlFor="name">Name</label>
                            <input className="w-full p-2 bg-gray-800 text-gray-300 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" type="text" name="name" id="name" required />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-300 text-sm font-bold mb-2" htmlFor="email">Email</label>
                            <input className="w-full p-2 bg-gray-800 text-gray-300 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" type="email" name="email" id="email" required />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-300 text-sm font-bold mb-2" htmlFor="message">Message</label>
                            <textarea className="w-full p-2 bg-gray-800 text-gray-300 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" name="message" id="message" rows="4" required></textarea>
                        </div>
                        <div className="mb-4">
                            <button className="w-full p-2 bg-blue-500 hover:bg-blue-600 text-white font-bold rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" type="submit">Send</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ContactMePage;
