import React from 'react';

class Timeline extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const experiences = [
            { year: "2022", title: "Job Title #1", company: "Company Name", description: "Brief description of your role and responsibilities." },
            { year: "2021", title: "Job Title #2", company: "Company Name", description: "Brief description of what you accomplished in this role." },
            { year: "2020", title: "Job Title #3", company: "Company Name", description: "Insight into projects or initiatives you led or contributed to." },
            // Add more experiences as needed
        ];

        return (
            <div className="px-4">
                <div className="flex flex-col items-start">
                    {experiences.map((exp, index) => (
                        <div key={index} className="w-full flex items-center mb-8 relative">
                            {/* Updated dot styling */}
                            <div className="flex items-center justify-center w-12 h-12 rounded-full border-4 border-blue-500 text-white font-bold mr-4 z-10 relative" style={{ backgroundColor: 'transparent' }}>
                                {exp.year}
                            </div>
                            <div className="flex flex-col flex-grow">
                                <div className="text-lg font-semibold text-gray-300">{exp.title} at {exp.company}</div>
                                <p className="text-gray-500">{exp.description}</p>
                            </div>
                            {index < experiences.length - 1 && (
                                <div className="w-1 bg-blue-500 absolute left-6 top-full bottom-[-2.1rem] z-0"></div> // Adjust the top and bottom to properly connect
                            )}
                        </div>
                    ))}
                </div>
            </div>
        )
        

       
    }

}

export default Timeline;