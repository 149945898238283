import React from 'react';
import Hero from './Hero';
import '../App.css';
import Timeline from './Timeline';
import Skills from './Skills';
import Carousel from './Carousel';
import NewTimeline from './NewTimeline';

function HomePage() {
  return (
    <div className="bg-gray-800">
      <Hero />
      <div className="my-20 p-4 md:p-8 lg:p-12">
        <NewTimeline />
      </div>
      <div className="my-20 p-4 md:p-8 lg:p-12">
        <Skills />
      </div>
    </div>
  );
}

export default HomePage;
