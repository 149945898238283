import React from 'react';
import ResponsiveCarousel from './Carousel'; // Ensure correct import path
import traveling from '../images/traveling.png';
import militaria from '../images/militaria.png';
import elmetto1 from '../images/elmetto1.jpg';
import elmetto2 from '../images/elmetto2.jpg';
import elmetto3 from '../images/elmetto3.jpg';
import elmetto4 from '../images/elmetto4.jpg';
import elmetto5 from '../images/elmetto5.jpg';
import elmetto6 from '../images/elmetto6.jpg';
import travel1 from '../images/travel1.jpg';
import travel2 from '../images/travel2.jpg';
import travel3 from '../images/travel3.jpg';
import travel4 from '../images/travel4.jpg';
import travel5 from '../images/travel5.jpg';

function AboutMeSection() {
    const slides1 = [
        {
            img: travel5,
            title: "Finland",
            description: "I have always been fascinated by Finland, and when a friend invited me to visit him in Lahti, I couldn't say no. This is a view from his summer vacation house."
        },
        {
            img: travel3,
            title: "Ventotene",
            description: "Ventotene is a small island in the Tyrrhenian Sea, off the coast of Italy. I like to go there to relax and enjoy the sea."
        },
        {
            img: travel1,
            title: "Rome",
            description: "I have been lucky enough to be born and raised in Rome, a city full of history and culture. I love to explore it and discover new places."
        },
        {
            img: travel2,
            title: "Norway",
            description: "Norwegian fjords are a must-see for any nature lover. I had the chance to visit them and I was amazed by their beauty. This picture is from the Geirangerfjord."
        },
        {
            img: travel4,
            title: "Cerveteri",
            description: "The etruscan necropolis of Cerveteri is a UNESCO World Heritage Site. The landscape looks out of this world, and it's a great place to visit if you're interested in history."
        }
    ];

    const slides2 = [
        {
            img: elmetto5,
            title: "M51 UNPROFOR",
            description: "M51 Helmet used in Bosnia by the UNPROFOR troops during the Yugoslav Wars. Incredibly hard to find piece, especially in this condition."
        },
        {
            img: elmetto4,
            title: "M33 RR.CC.",
            description: "Italian M33 helmet used by the Royal Carabinieri during the 1930s."
        },
        {
            img: elmetto1,
            title: "1St pattern German Pith",
            description: "1st pattern German Pith helmet used by the Deutsches Afrikakorps in Africa. Rare piece in this worn but almost complete condition"
        },
        {
            img: elmetto2,
            title: "Finnish M36 Field Cap",
            description: "Finnish M36 Field Cap used during the Winter War against the Soviet Union. Perfect condition, likely never issued."
        },
        {
            img: elmetto3,
            title: "Italian M35 Pith",
            description: "Italian M35 Pith helmet used by the Royal Italian Army during the Ethiopian war. Shows a custom insignia in use within the Italian Royal Airforce."
        },
        {
            img: elmetto6,
            title: "Italian PASGT Helmet",
            description: "Italian PASGT Helmet used by the Italian Army during the 1990s, belonging to a Liutenant Colonel, has his name and officers course written on the flock holder."
        }
    ];

    return (
        <div className="px-4 my-10">
            {/* About Me Description */}
            <div className="text-center mb-10">
                <h2 className="text-3xl md:text-4xl font-bold text-gray-300 mb-4">About Me</h2>
                <p className="text-base md:text-lg text-gray-500 text-left leading-relaxed mb-4">
                    I am a 23 year old boy with multiple passions. I initially undertook scientific studies given my interest in chemistry and biology, but I soon realized that my true passion was computer science, and this was the reason why I decided to pursue a career in this field.
                </p>
                <p className="text-base md:text-lg text-gray-500 text-left leading-relaxed mb-4">
                    In addition to this, I have a great passion for history developed over the years through books, video games and films. In particular, I have a great interest in military history.
                </p>
                <p className="text-base md:text-lg text-gray-500 text-left leading-relaxed mb-4">
                    Finally, I am a great mountaineering and trekking enthusiast, passions developed through 11 years of scouting which led me to explore wonderful places and make friendships that still last today.
                </p>
                <p className="text-base md:text-lg text-gray-500 text-left leading-relaxed">
                    Here you can find some slideshows regarding my main interests.
                </p>
            </div>
            <hr className="w-5/6 border-gray-500 mx-auto my-10" />

            {/* Traveling Section */}
            <h2 className="text-2xl md:text-3xl font-bold text-gray-300 mb-4 text-center">Traveling</h2>
            <ResponsiveCarousel slides={slides1} />
            <hr className="w-5/6 border-gray-500 mx-auto my-10" />

            {/* Collecting Section */}
            <h2 className="text-2xl md:text-3xl font-bold text-gray-300 mb-4 text-center mt-10">Collecting</h2>
            <ResponsiveCarousel slides={slides2} reverse />
        </div>
    );
}

export default AboutMeSection;
