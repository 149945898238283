import React from 'react';
import clogo from '../images/CLogo.png';
import csslogo from '../images/CSSLogo.png';
import discordpylogo from '../images/DiscordPyLogo.png';
import gitlogo from '../images/GitLogo.png';
import javalogo from '../images/JavaLogo.png';
import jquerylogo from '../images/JQueryLogo.png';
import linuxlogo from '../images/LinuxLogo.png';
import phplogo from '../images/PHPLogo.png';
import postgrelogo from '../images/PostgreLogo.png';
import pythonlogo from '../images/PythonLogo.png';
import reactlogo from '../images/ReactLogo.png';
import reduxlogo from '../images/ReduxLogo.png';
import tslogo from '../images/TSLogo.png';
import latexlogo from '../images/LatexLogo.png';
import sparqllogo from '../images/SparqlLogo.png';
import htmllogo from '../images/HTMLLogo.png';

class Skills extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const skills = [
      {
        area: 'Programming Languages',
        images: [pythonlogo, javalogo, clogo, postgrelogo, csslogo, phplogo, tslogo],
      },
      {
        area: 'Technologies and Frameworks',
        images: [reactlogo, jquerylogo, reduxlogo, discordpylogo],
      },
      {
        area: 'Others',
        images: [gitlogo, linuxlogo, latexlogo, sparqllogo, htmllogo],
      },
    ];

    return (
      <div className="p-4 md:p-8 lg:p-12">
        <h1 className="text-2xl md:text-3xl font-bold text-center mb-10 text-gray-300">Technical Skills</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {skills.map((skill, index) => (
            <div key={index} className="bg-gray-700 rounded-lg shadow p-4">
              <h2 className="text-xl font-bold mb-4 text-gray-300">{skill.area}</h2>
              <div className="grid grid-cols-3 gap-2">
                {skill.images.map((img, idx) => (
                  <img
                    key={idx}
                    src={img}
                    alt="Skill"
                    className="rounded-full shadow object-cover SkillImage"
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col items-center mt-10">
          <hr className="w-full md:w-2/3 border-gray-500" />
        </div>
      </div>
    );
  }
}

export default Skills;
