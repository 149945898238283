import React, { Component } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../App.css";

class ResponsiveCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSlide: 0
        };
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 3000,
            cssEase: "linear",
            adaptiveHeight: true,
            beforeChange: (current, next) => this.setState({ activeSlide: next })
        };

        const { slides, reverse } = this.props; // Get slides and reverse from props

        return (
            <div className={`flex flex-col md:flex-row h-auto items-stretch my-10 p-4 md:p-8 ${reverse ? 'md:flex-row-reverse' : ''}`}>
                <div className="md:w-1/2 flex flex-col justify-center mb-4 md:mb-0">
                    <Slider {...settings}>
                        {slides.map((slide, index) => (
                            <div key={index} className="CentraStaCazzoDiImmagine">
                                <img src={slide.img} alt={slide.title} className="max-w-full max-h-80 object-contain mx-auto" /> {/* Center and constrain image size */}
                            </div>
                        ))}
                    </Slider>
                </div>
                <div className="md:w-1/2 flex items-center justify-center p-4 md:p-10">
                    <div>
                        <h2 className="text-2xl md:text-4xl font-bold text-gray-300 text-center md:text-left">{slides[this.state.activeSlide].title}</h2>
                        <p className="text-base md:text-xl mt-4 text-gray-500 text-center md:text-left">{slides[this.state.activeSlide].description}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResponsiveCarousel;
