import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
    return (
        <header className="bg-gray-700 text-gray-300 shadow-md py-4">
            <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-4 md:px-6">
                <div className="flex flex-col md:flex-row items-center">
                    <Link to="/" className="text-lg font-semibold hover:text-gray-800 mr-0 md:mr-6 mb-2 md:mb-0">Home</Link>
                    <Link to="/projects" className="text-lg font-semibold hover:text-gray-800 mr-0 md:mr-6 mb-2 md:mb-0">About Me</Link>
                    <Link to="/contact" className="text-lg font-semibold hover:text-gray-800">Contact Me</Link>
                </div>
                <div className="mt-2 md:mt-0">
                    <a href="/TaruffiCVNuovo.pdf" download="TaruffiCVNuovo.pdf" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Download CV
                    </a>
                </div>
            </div>
        </header>
    );
}

export default Header;
