import React from 'react';

function Footer() {
    return (
        <footer className="bg-gray-700 text-gray-300 py-4 mt-auto">
            <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-4 md:px-6">
                <div className="flex justify-center mb-4 md:mb-0">
                    <a href="https://github.com/AlessioTaruffi" className="text-gray-300 hover:text-white mx-2">
                        <i className="fab fa-github"></i>
                    </a>
                    <a href="https://linkedin.com/in/alessio-taruffi-8bb809288/" className="text-gray-300 hover:text-white mx-2">
                        <i className="fab fa-linkedin-in"></i>
                    </a>
                    <a href="https://www.instagram.com/aanneboleynn/" className="text-gray-300 hover:text-white mx-2">
                        <i className="fab fa-instagram"></i>
                    </a>
                </div>
                <div className="text-sm text-center md:text-left">
                    This website was made using React.
                </div>
            </div>
        </footer>
    );
}

export default Footer;
