import React from 'react';

const projects = [
    { title: "React Generic Renderer", description: "I worked as an intern at HCL for 6 months realizing a generic JSON meta-language for the complete generalization of some components' creation and lifecycle management in HCL's Dynamic Workload Console product through the coding of a generic React renderer.", url: "https://help.hcltechsw.com/workloadautomation/v1021/common/src_gi/eqqg1dwconsole.html" },
    { title: "Website for a fictitious theater", description: "For the Languages and Technologies for the Web course, a website was developed that represented the online presence of a fictitious theatre. The site was implemented using Bootstrap CSS, jQuery, JavaScript, PHP, and PostgreSQL for the database. The test was scored with a 30.", url: "https://teatrostudentesco.it/it/HomePage.html" },
    { title: "Sana App", description: "For the human-computer interaction course, an application was created on the theme of services related to food and nutrition starting from the need finding phase, passing through prototyping and testing up to the creation of the final product. The test did not involve implementing the app via code. The test was awarded a 30 cum laude.", url: "https://github.com/AlessioTaruffi/Sana" },
    { title: "JBomberman", description: "For the Programming Methodologies course, a reproduction of the video game Bomberman for SNES was developed using the Java programming language. The project uses Swing for the graphics and GUI part, and includes both a predefined level mode including enemies, power-ups and bosses and a level editor to create levels from scratch.", url: "https://github.com/JBomberMan/JBomberMan" },
    { title: "Database for an eCommerce", description: "For the second module of the Data Bases course, a complex database relating to an online record store was created from scratch. We started from the ER to then move on to the restructuring of tables with PostgreSQL and the implementation of a GUI for interacting with Java.", url: "https://github.com/AlessioTaruffi/Database-for-a-CD-printing-company" },
    { title: "Sandro Bot", description: "A small discord bot to perform basic tasks related to the game Escape From Tarkov like checking the price of an item or the requirements to complete a given quest. Made using discord.py.", url: "https://github.com/AlessioTaruffi/SandroBot" }
];

function Projects() {
    return (
        <div className="p-4 md:p-10">
            <h1 className="text-2xl md:text-3xl font-bold text-gray-300 text-center mb-10">Projects I took part in</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {projects.map((project, index) => (
                    <div key={index} className="bg-gray-700 p-4 rounded-lg shadow-lg">
                        <h3 className="text-lg font-bold text-white">{project.title}</h3>
                        <hr className="my-2 border-gray-600" />
                        <p className="text-gray-300">{project.description}</p>
                        <hr className="my-2 border-gray-600" />
                        <a href={project.url} className="text-blue-400 hover:text-blue-600">View Project</a>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Projects;
