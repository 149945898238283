import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import HomePage from './HomePage';
import Projects from './Projects';
import AboutMeSection from './AboutMeSection'; // Ensure correct import path
import ContactMePage from './ContactMePage';

function App() {
    return (
        <Router>
            <div className="flex flex-col min-h-screen bg-gray-800 text-white">
                <Header />
                <main className="flex-grow p-4 md:p-8 lg:p-12">
                    <Routes>
                        <Route path="/" element={<>
                            <HomePage />
                            <Projects />
                        </>} exact />
                        <Route path="/projects" element={<AboutMeSection />} />
                        <Route path="/contact" element={<ContactMePage />} />
                    </Routes>
                </main>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
