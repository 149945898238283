import React from "react";
import profilo from '../images/profilo.jpg';

class Hero extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="flex flex-col items-center p-4 md:p-10 mt-5">
        <div className="flex flex-col md:flex-row items-center">
          <div className="flex-shrink-0">
            <img className="h-40 w-40 md:h-64 md:w-64 rounded-full border-2 border-gray-500" src={profilo} alt="Your Name" />
          </div>
          <div className="mt-4 md:mt-0 md:ml-8 text-center md:text-left">
            <h1 className="text-xl md:text-2xl font-bold text-gray-300">Hi there, Alessio here!</h1>
            <p className="text-sm md:text-base text-gray-500">
              I'm a bachelor in Computer Science at the University of Rome "La Sapienza". I graduated in May 2024 with a final grade of 102/110.
              I'm passionate about programming and I'm always looking for new challenges to improve my skills.
              I have been focussing on web development for the last period, mainly learning React, but I'm always on the lookout for new technologies that might interest me.
              Here you can find some info about me as well as some of my projects.
            </p>
          </div>
        </div>
        <hr className="w-full md:w-2/3 mt-10 border-gray-500" />
      </div>
    );
  }
}

export default Hero;
